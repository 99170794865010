import { useEffect, useState } from "react";
import { useLayoutContext } from "src/contexts/LayoutContext";
import Styles from "./Index.module.css";
import Papa from "papaparse";

interface ConsentChartDataRow {
  "CALL TYPE": string;
  "DIALER TYPE": string;
  "LINE TYPE": string;
  "CALL CONTENT": string;
  STATE: string;
  "CONSENT REQUIRED": string;
  "EBR EXEMPTS CONSENT": string;
}

const Index = () => {
  const { setHeading, setTagline } = useLayoutContext();
  const [consentChartData, setConsentChartData] = useState<
    ConsentChartDataRow[]
  >([]);

  useEffect(() => {
    Papa.parse("/cgdata/ConsentChartTable.csv", {
      download: true,
      header: true,
      complete: (result: Papa.ParseResult<ConsentChartDataRow>) => {
        setConsentChartData(result.data as ConsentChartDataRow[]);
      },
    });
  }, []);

  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Consent Chart");
      setTagline(
        "Consent requirements based on Call Type, Dialer Type, Call Content, Line Type, and State.",
      );
    }
  }, [setHeading, setTagline]);

  let currentCombination = "";
  let colorIndex = 0;
  const colorClasses = [
    Styles.rowColor1,
    Styles.rowColor2,
    Styles.rowColor3,
    Styles.rowColor4,
    Styles.rowColor5,
    Styles.rowColor6,
    Styles.rowColor7,
  ];

  return (
    <>
      <table className={Styles["compliance-table"]}>
        <thead>
          <tr>
            <th>CALL TYPE</th>
            <th>DIALER TYPE</th>
            <th>LINE TYPE</th>
            <th>CALL CONTENT</th>
            <th>STATE</th>
            <th>CONSENT REQUIRED</th>
            <th>EBR EXEMPTS CONSENT</th>
          </tr>
        </thead>
        <tbody>
          {consentChartData.length > 0 ? (
            consentChartData.map((row, index) => {
              const combination = `${row["CALL TYPE"]}_${row["DIALER TYPE"]}_${row["LINE TYPE"]}`;
              if (
                combination !== currentCombination &&
                row["CALL TYPE"] !== "" &&
                row["DIALER TYPE"] !== "" &&
                row["LINE TYPE"] !== ""
              ) {
                currentCombination = combination;
                colorIndex = (colorIndex + 1) % colorClasses.length;
              }

              return (
                <tr key={index} className={colorClasses[colorIndex]}>
                  <td>{row["CALL TYPE"]}</td>
                  <td>{row["DIALER TYPE"]}</td>
                  <td>{row["LINE TYPE"]}</td>
                  <td>{row["CALL CONTENT"]}</td>
                  <td>{row["STATE"]}</td>
                  <td>{row["CONSENT REQUIRED"]}</td>
                  <td>{row["EBR EXEMPTS CONSENT"]}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={10}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Index;
