import React from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import {
  IAccountSettings,
  ISubAccountUser,
} from "src/interfaces/Admin/ISubAccount";

interface SubAccountCreationSettingsProps {
  accountSettings: IAccountSettings;
  subAccountUser: ISubAccountUser;
}

const SubAccountCreationSettings: React.FC<SubAccountCreationSettingsProps> = ({
  accountSettings,
  subAccountUser,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      autoSubAccountNewProjs: accountSettings.autoSubAccountNewProjs,
      autoCreateSubAccountUser:
        accountSettings.autoSubAccountUserCreation % 2 !== 0,
      autoCreateSubAccountUserType:
        accountSettings.autoSubAccountUserCreation <= 3
          ? 2
          : accountSettings.autoSubAccountUserCreation <= 5
            ? 4
            : 6,
      subAccountUser: subAccountUser,
    },
  });

  const watchAutoCreateSubAccountUser = watch("autoCreateSubAccountUser");

  const onSubmit = async (data: any) => {
    const autoSubAccountUserCreation = data.autoCreateSubAccountUser
      ? data.autoCreateSubAccountUserType + 1
      : data.autoCreateSubAccountUserType;

    const updatedAccountSettings = {
      ...accountSettings,
      autoSubAccountNewProjs: data.autoSubAccountNewProjs,
      autoSubAccountUserCreation,
    };

    const updatedSubAccountUser = {
      ...subAccountUser,
      ...data.subAccountUser,
      status: data.subAccountUser.status ? 1 : 0, // Convert to integer
    };

    try {
      const apiUrl = `admin/SubAccounts/SaveSubAccountCreationSettings`;
      const method = HttpMethod.POST;
      const body = {
        AccountSettings: updatedAccountSettings,
        SubAccountUser: updatedSubAccountUser,
      };
      console.log("Updating with:", body);
      await callApi(apiUrl, method, body);
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="card mt-3">
      <div className="card mt-3">
        <h5 className="card-header">Sub-Account Creation Settings</h5>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 d-flex align-items-center">
              <input
                type="checkbox"
                className="me-2"
                {...register("autoSubAccountNewProjs")}
                defaultChecked={accountSettings.autoSubAccountNewProjs}
              />

              <label>
                Automatically Sub-Account-enable all newly created projects
                using the Default Sub-Account configuration
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex align-items-center">
              <input
                type="checkbox"
                className="me-2"
                {...register("autoCreateSubAccountUser")}
              />
              <label>
                Auto-create a user when a project is Sub-Account-enabled
              </label>
              <select
                {...register("autoCreateSubAccountUserType")}
                className="textbox1 ms-2"
              >
                <option value={2}>Automatically</option>
                <option value={4}>Manually</option>
                <option value={6}>Always</option>
              </select>
            </div>
            {watchAutoCreateSubAccountUser && (
              <>
                <div className="container border p-3">
                  <div className="row">
                    {/* Column 1 */}
                    <div className="col-md-4">
                      <div className="row mb-3">
                        <div className="col-md-6 d-flex align-items-center">
                          <label className="form-check-label ps-5">
                            Active
                          </label>
                          <input
                            type="checkbox"
                            className="ms-2 me-2"
                            {...register("subAccountUser.status")}
                            defaultChecked={subAccountUser.status === 1}
                            disabled
                          />

                          <span className="bi bi-check-circle-fill text-success fs-8"></span>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                          <label className="form-check-label">QuickScrub</label>
                          <input
                            type="checkbox"
                            className="ms-1"
                            {...register("subAccountUser.quickscrubEnabled")}
                            defaultChecked={
                              subAccountUser.quickscrubEnabled || false
                            }
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Username</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.username")}
                            defaultValue={subAccountUser.username || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Password</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="password"
                            {...register("subAccountUser.passwordObsolete")}
                            defaultValue={subAccountUser.passwordObsolete || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Full Name</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.fullName")}
                            defaultValue={subAccountUser.fullName || ""}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Column 2 */}
                    <div className="col-md-4">
                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Email</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.email", {
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Enter a valid email address",
                              },
                            })}
                            defaultValue={subAccountUser.email || ""}
                            className="form-control"
                          />
                          {errors.subAccountUser?.email && (
                            <p className="text-danger">
                              {errors.subAccountUser.email.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Phone</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.phone")}
                            defaultValue={subAccountUser.phone || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Extension</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.phoneExt")}
                            defaultValue={subAccountUser.phoneExt || ""}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Employee Number</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.employeeNum")}
                            defaultValue={subAccountUser.employeeNum || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Role</label>
                        </div>
                        <div className="col-md-8">
                          <select disabled={true} className="form-control ">
                            <option value="Supervisor">Supervisor</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Column 3 */}
                    <div className="col-md-4">
                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Division</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.division")}
                            defaultValue={subAccountUser.division || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Call Center Name</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.callCenterName")}
                            defaultValue={subAccountUser.callCenterName || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Department</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.department")}
                            defaultValue={subAccountUser.department || ""}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Shift</label>
                        </div>
                        <div className="col-md-8">
                          <select
                            {...register("subAccountUser.shift")}
                            defaultValue={subAccountUser.shift || "1"}
                            className="form-control"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-md-4 text-end">
                          <label>Station ID</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            {...register("subAccountUser.station")}
                            defaultValue={subAccountUser.station || ""}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mt-3">
                  Update Sub-Account Creation Settings
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SubAccountCreationSettings;
