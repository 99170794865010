import { useState, useEffect, useCallback } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { useForm } from "react-hook-form";
import Warning from "src/components/Warning";
import SafeHtml from "src/components/SafeHtml";
import EmailTextarea from "src/components/EmailTextarea";
import SaveInProgress from "src/components/SaveInProgress";

const TrainingReminder = () => {
  const { projectId, campaignId } = useUploadListContext();
  const [initialValues, setInitialValues] = useState({});
  const [warningMessage, setWarningMessage] = useState("");
  const [description, setDescription] = useState("");

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    formState: { isValid },
  } = useForm();

  const [saveInProgress, setSaveInProgress] = useState(false);
  const fetchTrainingReminderData = useCallback(async () => {
    try {
      const apiUrl = `main/Projects/GetTrainingReminderSettings?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      // Object.keys(response).forEach((key) => {
      //   setValue(key, response[key]);
      // });
      setDescription(response.description);
      setInitialValues(response.data);
      reset(response.data);
    } catch (error) {
      console.error("Failed to fetch project data:", error);
    }
  }, [projectId, campaignId, reset]);

  useEffect(() => {
    fetchTrainingReminderData();
  }, [fetchTrainingReminderData]);

  const onSubmit = async (data: any) => {
    setSaveInProgress(true);
    setWarningMessage("");
    try {
      const isSendNow = data.trainingReminderRunNow === 1;
      if (isSendNow) {
        data.trainingReminderRunNow = 1;
      }

      const apiUrl = `main/Projects/UpdateTrainingReminderSettings`;
      await callApi(apiUrl, HttpMethod.POST, data);
      fetchTrainingReminderData();
    } catch (error) {
      setWarningMessage("Failed to save settings.");
      console.error("Failed to save settings:", error);
    }
    setSaveInProgress(false);
  };

  const saveAndSendEmailNow = async () => {
    setValue("trainingReminderRunNow", 1, { shouldDirty: true });
    await trigger();
  };

  const currentValues = watch();
  const formIsDirty =
    JSON.stringify(currentValues) !== JSON.stringify(initialValues);

  return (
    <>
      <h6 style={{ display: "inline", paddingRight: 10 }}>
        TrainingMaster settings
      </h6>
      <div className="mt-3">
        <SafeHtml html={description} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Warning message={warningMessage} />
        <input type="hidden" {...register("projId")} value={projectId} />
        <input type="hidden" {...register("trainingReminderRunNow")} />
        <hr />
        <div className="row">
          <label>
            <input
              type="checkbox"
              className="form-check-input"
              {...register("trainingReminderActive")}
            />
            <span className="ps-2">
              Enable TrainingMaster status report for this Project.
            </span>
          </label>
        </div>
        <div className="row">
          <label>
            <input
              type="checkbox"
              className="form-check-input"
              {...register("trainingReminderAssignmentsOnly")}
            />
            <span className="ps-2">
              Filter report to show only those users who have been assigned to
              take the test.
            </span>
          </label>
        </div>
        <div className="pt-3">
          <h6>E-mail reports To:</h6>
          <EmailTextarea name="trainingReminderRecipients" control={control} />
        </div>
        <div className="pt-3">
          <h6>Day of week to receive status report:</h6>
          <select
            className="form-select"
            {...register("trainingReminderDayOfWeek")}
          >
            <option value="0">Sunday</option>
            <option value="1">Monday</option>
            <option value="2">Tuesday</option>
            <option value="3">Wednesday</option>
            <option value="4">Thursday</option>
            <option value="5">Friday</option>
            <option value="6">Saturday</option>
          </select>
        </div>
        <div className="pt-3">
          <h6>Frequency of updates:</h6>
          <select
            className="form-select"
            {...register("trainingReminderFrequency")}
          >
            <option value="7">Every week</option>
            <option value="14">Every other week</option>
            <option value="28">Every fourth week</option>
          </select>
        </div>
        <hr />
        <button
          className="btn btn-primary me-3"
          type="submit"
          disabled={!formIsDirty && !isValid}
        >
          {!saveInProgress ? (
            "Save"
          ) : (
            <SaveInProgress isVisible={saveInProgress} />
          )}
        </button>
        <button
          className="btn btn-primary me-3"
          onClick={saveAndSendEmailNow}
          disabled={!formIsDirty && !isValid}
        >
          {!saveInProgress ? (
            "Save and Send Email Now"
          ) : (
            <SaveInProgress isVisible={saveInProgress} />
          )}
        </button>
      </form>
    </>
  );
};

export default TrainingReminder;
