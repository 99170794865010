import React, { useState, useRef } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ShowIcon from "src/pages/Admin/SubAccounts/ShowIcon";
import { ICpaSection, IProject } from "src/interfaces/Admin/ISubAccount";

interface MyProjectsProps {
  cpaSection: ICpaSection;
  myProjects: IProject[];
  refreshData: () => void;
}

interface IProjectForm {
  projects: Partial<IProject>[];
}

const MyProjects: React.FC<MyProjectsProps> = ({
  cpaSection,
  myProjects,
  refreshData,
}) => {
  const originalValues = useRef(myProjects);

  const {
    register,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<IProjectForm>({
    defaultValues: { projects: myProjects },
  });
  const watchedProjects = watch("projects");

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleReset = () => {
    reset({ projects: myProjects });
  };

  const myProjectsAll = () => {
    const checkboxes = document.querySelectorAll(".project-checkbox");
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => (checkbox as HTMLInputElement).checked,
    );

    // Toggle all checkboxes based on the current state
    checkboxes.forEach((checkbox) => {
      (checkbox as HTMLInputElement).checked = !allChecked;
    });
  };

  const handleRowSelection = (projId: string, isChecked: boolean) => {
    setSelectedRows((prevSelected) =>
      isChecked
        ? [...prevSelected, projId]
        : prevSelected.filter((id) => id !== projId),
    );
  };

  const handleEnableSelectedProjects = async () => {
    try {
      const apiUrl = `admin/SubAccounts/EnableSubAccounts`;
      const method = HttpMethod.POST;
      const body = selectedRows;

      // Async call to save the data
      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
      if (typeof refreshData === "function") {
        refreshData(); // Calls fetchSubAccounts in the parent
      } else {
        console.log("Unable to call RefreshData");
      }
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  const handleDisableSelectedProjects = async () => {
    try {
      const apiUrl = `admin/SubAccounts/DisableSubAccounts`;
      const method = HttpMethod.POST;
      const body = selectedRows;

      // Async call to save the data
      await callApi(apiUrl, method, body);
      console.log("Settings saved successfully");
      if (typeof refreshData === "function") {
        console.log("RefreshData called");
        refreshData(); // Calls fetchSubAccounts in the parent
      } else {
        console.log("Unable to call RefreshData");
      }
    } catch (error) {
      console.error("Failed to save settings:", error);
    }
  };

  const findModifiedRows = () => {
    return watchedProjects
      .map((project, index) => ({
        original: originalValues.current[index],
        modified: project,
        projId: myProjects[index].projId,
      }))
      .filter(({ original, modified }) => {
        if (!original.isSubAccount) return false;

        return JSON.stringify(original) !== JSON.stringify(modified);
      })
      .map(({ projId, modified }) => ({ projId, ...modified }));
  };

  const handleUpdateSettings = async () => {
    //const dirtyRecords = getDirtyRecords();

    const modifiedRows = findModifiedRows();
    if (modifiedRows.length === 0) {
      console.log("No changes to update");
      return;
    }

    const sanitizedRows = modifiedRows.map((row) => ({
      ...row,
      agentsLimit: String(row.agentsLimit) === "" ? null : row.agentsLimit,
      blockAfterScrubsReaches:
        String(row.blockAfterScrubsReaches) === ""
          ? null
          : row.blockAfterScrubsReaches,
      blockAfterPoliciesSentReaches:
        String(row.blockAfterPoliciesSentReaches) === ""
          ? null
          : row.blockAfterPoliciesSentReaches,
      quickScrubUsersLimit:
        String(row.quickScrubUsersLimit) === ""
          ? null
          : row.quickScrubUsersLimit,
    }));

    try {
      const apiUrl = `admin/SubAccounts/UpdateProjects`;
      const method = HttpMethod.POST;

      // Send only modified rows in the body
      await callApi(apiUrl, method, sanitizedRows);

      console.log("Settings updated successfully");
      if (typeof refreshData === "function") {
        refreshData(); // Refresh parent data
      }
    } catch (error) {
      console.error("Failed to update settings:", error);
    }
  };

  return (
    <form>
      <div className="card my-3">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>My Projects</span>
          <div className="ps-2 pe-2">
            <strong>Legend:</strong>
            <div className="d-flex align-items-center">
              <ShowIcon type="UsesMaster" /> Uses Master
              <span className="mx-2">|</span>
              <ShowIcon type="SubAccount" /> Sub-Account Enabled
              <span className="mx-2">|</span>
              <ShowIcon type="RetroScrub" /> RetroScrub Allowed
              <span className="mx-2">|</span>
              <ShowIcon type="EBRNotify" /> EBRNotify Allowed
              <span className="mx-2">|</span>
              <ShowIcon type="ScrubUpload" /> Scrub via Upload
              <span className="mx-2">|</span>
              <ShowIcon type="QuickScrub" /> QuickScrub
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="card-text">
            <table className="table table-bordered mt-2">
              <thead>
                <tr>
                  <th>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={myProjectsAll}
                    >
                      All
                    </button>
                  </th>
                  <th>DNC</th>
                  <th>EBR</th>
                  <th>Policy</th>
                  {!cpaSection.projectsCannotUseMaster && <th>SAN</th>}
                  {cpaSection.displayTraining && <th>Training</th>}
                  <th>Project Name</th>
                  <th>Project Code / Sub-Account Code</th>
                  <th>Features</th>
                  <th>Supervisors can</th>
                  <th>Supervisors can remove records from these DB's</th>
                  <th>Agents can</th>
                  {!cpaSection.projectsCannotUseMaster && (
                    <th>Monthly Scrub Limit</th>
                  )}
                  <th>Max Policies Sent / mo</th>
                  {!cpaSection.automaticQuickScrubForSupervisorsAdmins && (
                    <th>
                      Max QuickScrub
                      <br /> Enabled Users
                    </th>
                  )}
                  <th>Max Agents</th>
                </tr>
              </thead>
              <tbody>
                {myProjects.map((project, index) => (
                  <tr key={project.projId}>
                    <td>
                      <input
                        type="checkbox"
                        className="project-checkbox"
                        onChange={(e) =>
                          handleRowSelection(project.projId, e.target.checked)
                        }
                      />
                    </td>
                    <td>
                      {project.usesMasterProjDNCdb && (
                        <ShowIcon type="UsesMaster" isEnabled={true} />
                      )}
                    </td>
                    <td>
                      {project.usesMasterProjEBRdb && (
                        <ShowIcon type="UsesMaster" isEnabled={true} />
                      )}
                    </td>
                    <td>
                      {project.usesMasterProjDNCpolicy && (
                        <ShowIcon type="UsesMaster" isEnabled={true} />
                      )}
                    </td>
                    {!cpaSection.projectsCannotUseMaster && (
                      <td>
                        {project.usesMasterProjDNCsub && (
                          <ShowIcon type="UsesMaster" isEnabled={true} />
                        )}
                      </td>
                    )}

                    {cpaSection.displayTraining && (
                      <td>
                        {project.usesMasterProjTraining && (
                          <ShowIcon type="UsesMaster" isEnabled={true} />
                        )}
                      </td>
                    )}

                    <td>
                      <Link
                        to={`/main/project-settings/${project.campaignId}`}
                        style={{ textDecoration: "none" }}
                      >
                        {project.name}
                      </Link>
                    </td>

                    <td className="text-nowrap">
                      {project.isSubAccount ? (
                        <>
                          <ShowIcon type="SubAccount" isEnabled={true} />
                          <Link
                            to={`/t/users?u-admin.adp|add=0&subAcctId=${project.projId}`}
                            style={{ textDecoration: "none" }}
                          >
                            {project.projId}
                          </Link>
                        </>
                      ) : (
                        <>
                          <ShowIcon type="SubAccount" isEnabled={false} />
                          {project.projId}
                        </>
                      )}
                    </td>

                    <td className="text-nowrap">
                      <span className="me-2">
                        <input
                          type="checkbox"
                          className="me-1"
                          disabled={
                            !project.isSubAccount || !cpaSection.canScrubRetro
                          }
                          defaultChecked={project.retroScrubEnabled}
                          {...register(`projects.${index}.retroScrubEnabled`)}
                        />
                        <ShowIcon
                          type="RetroScrub"
                          isEnabled={project.retroScrubEnabled}
                        />
                      </span>

                      <span className="me-1">
                        <input
                          type="checkbox"
                          className="me-1"
                          disabled={
                            !project.isSubAccount || !cpaSection.canNotifyEbr
                          }
                          defaultChecked={project.ebrNotifyEnabled}
                          {...register(`projects.${index}.ebrNotifyEnabled`)}
                        />

                        <ShowIcon type="EBRNotify" isEnabled={false} />
                      </span>
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="me-1"
                        disabled={
                          !project.isSubAccount || project.s_scrub_upload < -8
                        }
                        defaultChecked={project.s_scrub_upload_checked}
                        {...register(
                          `projects.${index}.s_scrub_upload_checked`,
                        )}
                      />
                      <ShowIcon
                        type="ScrubUpload"
                        isEnabled={project.s_scrub_upload > 0}
                      />
                      <input
                        type="checkbox"
                        className="me-1"
                        disabled={
                          !project.isSubAccount ||
                          project.s_scrub_quick < -8 ||
                          !cpaSection.incQuickScrubUsers
                        }
                        defaultChecked={project.s_scrub_quick_checked}
                        {...register(`projects.${index}.s_scrub_quick_checked`)}
                      />
                      <ShowIcon
                        type="QuickScrub"
                        isEnabled={project.s_scrub_quick > 0}
                      />
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="me-1"
                        disabled={
                          !project.isSubAccount || project.s_delete_pdnc < -8
                        }
                        defaultChecked={project.s_delete_pdnc_checked}
                        {...register(`projects.${index}.s_delete_pdnc_checked`)}
                      />
                      DNC
                      <br />
                      <input
                        type="checkbox"
                        className="me-1"
                        disabled={
                          !project.isSubAccount || project.s_delete_ebr < -8
                        }
                        defaultChecked={project.s_delete_ebr_checked}
                        {...register(`projects.${index}.s_delete_ebr_checked`)}
                      />
                      EBR
                    </td>

                    <td>
                      <input
                        type="checkbox"
                        className="me-1"
                        disabled={
                          !project.isSubAccount || project.a_delete_pdnc < -8
                        }
                        defaultChecked={project.a_delete_pdnc_checked}
                        {...register(`projects.${index}.a_delete_pdnc_checked`)}
                      />
                      Remove from DNC
                      <br />
                      <input
                        type="checkbox"
                        className="me-1"
                        disabled={
                          !project.isSubAccount ||
                          project.a_scrub_quick < -8 ||
                          !cpaSection.incQuickScrubUsers
                        }
                        defaultChecked={project.a_scrub_quick_checked}
                        {...register(`projects.${index}.a_scrub_quick_checked`)}
                      />
                      <ShowIcon
                        type="QuickScrub"
                        isEnabled={project.a_scrub_quick > 0}
                      />
                    </td>
                    {!cpaSection.projectsCannotUseMaster && (
                      <td>
                        <div style={{ width: "50px", overflow: "hidden" }}>
                          <input
                            type="text"
                            style={{ width: "100%" }}
                            defaultValue={project.blockAfterScrubsReaches ?? ""}
                            disabled={!project.isSubAccount}
                            {...register(
                              `projects.${index}.blockAfterScrubsReaches`,
                            )}
                          />
                        </div>
                      </td>
                    )}
                    <td>
                      <div style={{ width: "50px", overflow: "hidden" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            padding: "2px",
                            margin: "0",
                            boxSizing: "border-box",
                          }}
                          defaultValue={
                            project.blockAfterPoliciesSentReaches ?? ""
                          }
                          disabled={!project.isSubAccount}
                          {...register(
                            `projects.${index}.blockAfterPoliciesSentReaches`,
                          )}
                        />
                      </div>
                    </td>
                    {!cpaSection.automaticQuickScrubForSupervisorsAdmins && (
                      <td>
                        <div style={{ width: "50px", overflow: "hidden" }}>
                          <input
                            type="text"
                            style={{
                              width: "100%",
                              padding: "2px",
                              margin: "0",
                              boxSizing: "border-box",
                            }}
                            defaultValue={project.quickScrubUsersLimit ?? ""}
                            disabled={!project.isSubAccount}
                            {...register(
                              `projects.${index}.quickScrubUsersLimit`,
                            )}
                          />
                        </div>
                      </td>
                    )}
                    <td>
                      <div style={{ width: "50px", overflow: "hidden" }}>
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            padding: "2px",
                            margin: "0",
                            boxSizing: "border-box",
                          }}
                          defaultValue={project.agentsLimit ?? ""}
                          disabled={!project.isSubAccount}
                          {...register(`projects.${index}.agentsLimit`)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="card-footer text-muted">
          <button
            className="btn btn-primary me-2"
            onClick={handleEnableSelectedProjects}
            type="button"
            disabled={selectedRows.length === 0}
          >
            Sub-Account Enable All Selected Projects
          </button>
          <button
            className="btn btn-primary me-2"
            onClick={handleUpdateSettings}
            type="button"
            disabled={!isDirty}
          >
            Update Settings
          </button>
          <button
            className="btn btn-primary me-2"
            onClick={handleReset}
            type="button"
            disabled={!isDirty}
          >
            Reset
          </button>
          <br />
          <button
            className="btn btn-primary mt-2"
            onClick={handleDisableSelectedProjects}
            type="button"
            disabled={selectedRows.length === 0}
          >
            Sub-Account Disable All Selected Projects
          </button>
        </div>
      </div>
    </form>
  );
};

export default MyProjects;
