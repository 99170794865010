import styles from "./LitigatorScrubSettingsDocumentation.module.css";

interface LitigatorScrubSettingsDocumentationProps {
  showInScrubOptions: boolean;
}

const LitigatorScrubSettingsDocumentation: React.FC<
  LitigatorScrubSettingsDocumentationProps
> = ({ showInScrubOptions }) => {
  return (
    <div
      className={`p-3 ${
        !showInScrubOptions ? "card rounded " + styles.instructions : ""
      }`}
    >
      <h4>
        <i className="bi bi-book"></i> Litigator Scrub Settings Documentation
      </h4>
      Users can enable specific feeds to target contacts based on their
      litigation history and affiliations. The available feeds include:
      <ul className={`p-3 ${!showInScrubOptions ? styles.instructions : ""}`}>
        <li>
          <b>TCPA Plaintiffs:</b> Remove individuals known to file lawsuits
          under the Telephone Consumer Protection Act (TCPA).
        </li>
        <li>
          <b>TCPA Plaintiffs Known Family Members:</b> Remove known family
          members of TCPA plaintiffs.
        </li>
        <li>
          <b>TCPA Plaintiffs Known Associates:</b> Remove contacts linked to
          TCPA plaintiffs, such as colleagues or business partners.
        </li>
        <li>
          <b>FDCPA, TIL, FCRA Plaintiffs:</b> Identify individuals associated
          with lawsuits related to the Fair Debt Collection Practices Act
          (FDCPA), Truth in Lending Act (TIL), or Fair Credit Reporting Act
          (FCRA).
        </li>
        <li>
          <b>TCPA Demand Letters:</b> Remover contacts who have issued
          TCPA-related demand letters.
        </li>
        <li>
          <b>TCPA Crowdsourced:</b> Remove contacts flagged by crowdsourced data
          on TCPA activity.
        </li>
        <li>
          <b>Attorneys:</b> Remove contacts identified as attorneys
        </li>
      </ul>
    </div>
  );
};

export default LitigatorScrubSettingsDocumentation;
