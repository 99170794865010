import { useEffect, useState } from "react";
import * as React from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { IQuickScrubList } from "src/interfaces/Main/IQuickScrubList";
import { IQuickScrubResults } from "src/interfaces/Main/IQuickScrubResults";
import regionData from "src/data/regions.json";
import timeZoneData from "src/data/timeZones.json";
import IJsonMapper from "src/interfaces/IJsonMapper";
import LocalTime from "src/components/LocalTime";
import Swal from "sweetalert2";
import ScrubWarnings from "./ScrubWarnings";
import { store } from "src/app/store";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Warning from "src/components/Warning";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";

const QuickScrubResults: React.FC = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { phoneList } = (location.state as IQuickScrubList) || {
    phoneList: null,
  }; // Destructure with default fallback
  const [data, setData] = useState<IQuickScrubResults[]>([]);
  const regions: IJsonMapper = regionData as IJsonMapper;
  const timeZones: IJsonMapper = timeZoneData as IJsonMapper;
  const [hasPhoneList, setHasPhoneList] = useState(true);
  const { projectId, campaignId } = useUploadListContext();
  const [showWarning, setShowWarning] = useState("");
  const [addedNumbers, setAddedNumbers] = useState<string[]>([]);

  useEffect(() => {
    setShowWarning("");
    const state = store.getState();
    const { loginId } = state.auth;
    const fetchData = async () => {
      try {
        if (phoneList) {
          const response = await axios.post(
            "https://www.dncscrub.com/app/main/rpc/scrub",
            {
              phoneList: phoneList.replace(/\D/g, ","),
              version: "5",
              output: "json",
              campaignId: campaignId,
              qs: "1", // Used to indicate quickscrub being done
            },
            {
              headers: {
                loginId: loginId,
                "Content-Type": "application/json",
              },
              // Note the loginId cookie is not passed by axios
              // would need to figure out why if loginId was removed
              // from redux
            },
          );

          // Make sure that the response is an array and is not empty
          const sortedData = Array.isArray(response.data)
            ? response.data.sort(
                (a: IQuickScrubResults, b: IQuickScrubResults) =>
                  a.Phone.localeCompare(b.Phone),
              )
            : [];
          setData(sortedData);
        } else {
          setHasPhoneList(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowWarning("There was an error fetching the data.");
        //setData(null);
      }
    };

    fetchData();
  }, [phoneList, campaignId]);

  function getCallingWindow(callingWindow: string): string {
    const times = callingWindow.split(";");
    const today = new Date().getDay();

    // Change from if to switch
    switch (today) {
      case 0:
        // Sunday
        return times[2] || "No calling window set";
      case 6:
        // Saturday
        return times[1] || "No calling window set";
      default:
        // Weekday
        return times[0] || "No calling window set";
    }
  }

  function processReason(reason: string, reasonCode: string) {
    const parts = reason.split(";");
    const firstPart = parts[0].trim();

    if (reasonCode === "P") {
      return "Internal DNC Match";
    }

    if (reasonCode === "D") {
      const firstTwoParts = parts.slice(0, 2);
      const processedParts = firstTwoParts.map((part) => {
        const replaced = part.replace(/\(.*?\)/g, "DNC");
        const updated = replaced.replace(
          /(\d{4})-(\d{2})-(\d{2})/,
          (_, year, month, day) => `since ${month}-${day}-${year}`,
        );
        return updated.trim();
      });
      return processedParts.filter((part) => part !== "").join("\t");
    }

    return firstPart || "";
  }

  function ebrDescription(ebrType: string): string {
    switch (ebrType) {
      case "S":
        return "Sale";
      case "I":
        return "Inquiry";
      case "P":
        return "Permission";
      case "R":
        return "Recent Sale";
      case "N":
        return "Newspaper Trial";
      default:
        return "";
    }
  }

  function getStatusDescription(status: string): string {
    switch (status) {
      case "B":
        return "Blocked";
      case "C":
        return "Clean";
      case "D":
        return "DNC";
      case "E":
        return "EBR";
      case "F":
        return "EBR";
      case "G":
        return "EBR";
      case "H":
        return "EBR";
      case "I":
        return "Invalid";
      case "L":
        return "No Wireless State";
      case "M":
        return "Invalid";
      case "O":
        return "EBR";
      case "P":
        return "DNC";
      case "V":
        return "Clean";
      case "W":
        return "Clean";
      case "X":
        return "Excemption";
      case "Y":
        return "Clean";

      default:
        return "";
    }
  }
  function formatPhoneNumber(phoneNumber: string) {
    // Ensure the phone number is a string to perform slice operations
    const number = phoneNumber.toString();

    // Extract the area code, the middle three digits, and the last four digits
    const areaCode = number.slice(0, 3);
    const middleThree = number.slice(3, 6);
    const lastFour = number.slice(6);

    // Format and return the phone number
    return `(${areaCode}) ${middleThree}-${lastFour}`;
  }

  function convertTimeToAmPm(timeRange: string): string {
    // Abhi - Some numbers do not return a time range
    if (!timeRange) return "";

    // Split the range into start and end times
    const [startTime, endTime] = timeRange.split("-");
    if (!startTime || !endTime) return ""; // Invalid time range

    // Convert the time to AM/PM format
    const toAmPm = (time: string): string => {
      //let [hours, minutes] = time.split(":").map(Number);
      const [stringHours, stringMinutes] = time.split(":");

      if (!stringHours || !stringMinutes) return "";
      // AK: since we are no longer mapping to a number we need to now convert to a number
      let hours = parseInt(stringHours, 10);
      const minutes = parseInt(stringMinutes, 10);

      if (isNaN(hours) || isNaN(minutes)) return "";

      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12; // Convert '0' to '12' for 12-hour format
      return `${hours}:${minutes.toString().padStart(2, "0")}${ampm}`;
    };

    return `${toAmPm(startTime)} - ${toAmPm(endTime)}`;
  }

  const handleAddClick = async (phoneNumber: string) => {
    // Using SweetAlert2 to show a confirmation dialog
    const result = await Swal.fire({
      title: "Add to Internal DNC list?",
      html: `Are you sure you want to add the number below to the Internal DNC list? <br/>&nbsp;<br/><strong>${phoneNumber}</strong>`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    // Checking if user confirmed the action
    if (result.isConfirmed) {
      try {
        const dataToSend = {
          fullPhone: phoneNumber.replace(/\D/g, ""),
          isDNC: true,
          isDNM: false,
        };

        setIsLoading(true);
        const apiUrl = `main/dncdnm/add?projId=${projectId}&campaignId=${campaignId}&addToMaster=false`;
        await callApi(apiUrl, HttpMethod.POST, dataToSend);
        setAddedNumbers([...addedNumbers, phoneNumber]);
        console.log("API call successful");

        setShowWarning("");
      } catch (error) {
        console.error("API call failed:", error);
        setShowWarning("There was an error adding the number to the list.");
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("User cancelled the action");
    }
  };

  const callableResultCode = ["C", "E", "F", "G", "H", "O", "V", "W", "Y"];
  const callableRecords =
    data.filter((record) => callableResultCode.includes(record.ResultCode)) ||
    [];
  const nonCallableRecords =
    data?.filter((record) => !callableResultCode.includes(record.ResultCode)) ||
    [];

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {!hasPhoneList ? (
        <div className="container-fluid">
          <b>Quick Scrub:</b> Simply enter or paste phone numbers, one per line,
          into the QuickScrub text entry box in the right column. Then press the
          Scrub It button to get an instant report of the DNC and EBR status of
          these numbers.
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Quick Scrub Results</h1>
            </div>
          </div>
        </div>
      )}

      <Warning message={showWarning} />

      <ScrubWarnings />

      {callableRecords && callableRecords.length > 0 && (
        <div className="card">
          <div className="card-header">
            <h3>Callable Numbers</h3>
          </div>
          <div className="card-body">
            <table className="w-100 table">
              <thead>
                <tr>
                  <th className="text-start ps-2">Status</th>
                  <th className="text-start ps-2">Number</th>
                  <th className="text-start ps-2">Location</th>
                  <th className="text-start ps-2">Time Zone</th>
                  <th className="text-start ps-2">Local Time</th>
                  <th className="text-start ps-2">Call Window</th>
                  <th>EBR Info</th>
                  <th className="text-start ps-2">IDNC</th>
                </tr>
              </thead>
              <tbody>
                {callableRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{getStatusDescription(record.ResultCode)}</td>
                    <td>
                      {formatPhoneNumber(record.Phone)}{" "}
                      {record.LineType === "Wireless" ? (
                        <i className="bi bi-phone ps-2"></i>
                      ) : record.LineType === "VoIP" ? (
                        <i className="bi bi-display ps-2"></i>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>{regions[record.RegionAbbrev] || ""}</td>
                    <td>
                      {(timeZones[record.TZCode] || "").replace(" Time", "")}
                    </td>
                    <td>
                      <LocalTime UTCOffset={record.UTCOffset || ""} />
                    </td>
                    <td>
                      {convertTimeToAmPm(
                        getCallingWindow(record.CallingWindow),
                      )}
                    </td>
                    <td>
                      {record.DoNotCallToday === "1" ? (
                        <>(DNC Today)</>
                      ) : (
                        <>
                          {record.EBRType
                            ? `(EBR - ${ebrDescription(record.EBRType)})`
                            : ""}
                        </>
                      )}
                    </td>
                    <td>
                      {addedNumbers.includes(record.Phone) ? (
                        <span className="text-success">
                          <b>Added</b>
                        </span>
                      ) : (
                        <button
                          onClick={() => handleAddClick(record.Phone)}
                          className="btn btn-primary btn-sm"
                        >
                          Add
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {nonCallableRecords && nonCallableRecords.length > 0 && (
        <div className="card">
          <div className="card-header">
            <h3>Non Callable Numbers</h3>
          </div>
          <div className="card-body">
            <table className="w-100 table">
              <thead>
                <tr>
                  <th className="text-start ps-2">Status</th>
                  <th className="text-start ps-2">Number</th>
                  <th className="text-start ps-2">DNC Info</th>
                </tr>
              </thead>
              <tbody>
                {nonCallableRecords.map((record, index) => (
                  <tr key={index}>
                    <td>{getStatusDescription(record.ResultCode)}</td>
                    <td className="text-nowrap ps-2">
                      {formatPhoneNumber(record.Phone)}
                      {record.LineType === "Wireless" ? (
                        <i className="bi bi-phone ps-2"></i>
                      ) : record.LineType === "VoIP" ? (
                        <i className="bi bi-display ps-2"></i>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className="w-100 table">
                      {processReason(record.Reason, record.ResultCode)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="pt-3">
        <div className="pt-3 pb-1">
          <b>Image Legend:</b>
        </div>
        <i className="bi bi-phone"></i> - Indicates Wireless Phone Number
        <br />
        <i className="bi bi-display"></i>- Indicates VoIP Phone Number
        <br />
        &nbsp;
        <br />
        Wireless and VoIP scrubbing must be enabled for these icons to show
      </div>
      <div className="d-none">
        <h1>Results</h1>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </>
  );
};

export default QuickScrubResults;
