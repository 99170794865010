import { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import Warning from "src/components/Warning";
import ISubAccount from "src/interfaces/Admin/ISubAccount";

import SubAccountCreationSettings from "src/pages/Admin/SubAccounts/SubAccountCreationSettings";
import MyProjects from "src/pages/Admin/SubAccounts/MyProjects";

const Main = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [subAccounts, setSubAccounts] = useState<ISubAccount>();

  const fetchSubAccounts = async () => {
    setIsLoading(true);
    try {
      setWarningMessage("");
      const apiUrl = "admin/subaccounts";
      const response = await callApi(apiUrl, HttpMethod.GET);
      setSubAccounts(response);
    } catch (error) {
      console.error("Failed to fetch SubAccounts:", error);
      setWarningMessage("Failed to fetch SubAccounts.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubAccounts();
  }, []);

  if (isLoading || !subAccounts) {
    return <Loading />;
  }

  return (
    <div style={{ height: "calc(100vh - 175px)" }}>
      <h3>Sub-Accounts</h3>
      <div className="card">
        <h5 className="card-header">Converting a Project into a Sub-Account</h5>
        <div className="card-body">
          <p className="card-text">{subAccounts.Description}</p>
        </div>
      </div>
      <SubAccountCreationSettings
        accountSettings={subAccounts.FormFields.AccountSettings}
        subAccountUser={subAccounts.FormFields.SubAccountUser}
      />
      <Warning message={warningMessage} />
      <MyProjects
        cpaSection={subAccounts.CpaSection}
        myProjects={subAccounts.FormFields.ProjectsList}
        refreshData={fetchSubAccounts}
      />
    </div>
  );
};

export default Main;
