import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import { RetroScrubSettings } from "src/types/RetroScrubSettings";
import Warning from "src/components/Warning";
import EmailTextarea from "src/components/EmailTextarea";
import SaveInProgress from "src/components/SaveInProgress";

const mapDayOfWeek = (dayNum: number) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[dayNum];
};

const RetroScrub = () => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const { projectId, campaignId } = useUploadListContext();
  const [warningMessage, setWarningMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm<RetroScrubSettings>({
    defaultValues: {
      projId: null,
      active: null,
      maxAgeDays: null,
      frequency: null,
      toAddress: null,
      nextDate: null,
      dayOfWeek: null,
      canRetro: false,
    },
  });

  const canRetro = watch("canRetro");
  const nextDate = watch("nextDate");
  const dayOfWeek = watch("dayOfWeek");
  const maxAgeDays = watch("maxAgeDays");
  const frequency = watch("frequency");

  useEffect(() => {
    const fetchRetroScrubData = async () => {
      setIsLoading(true);
      try {
        const apiUrl = `Main/Projects/GetRetroScrubSettings?projId=${projectId}&campaignId=${campaignId}`;
        const response = await callApi(apiUrl, HttpMethod.GET);

        for (const [key, value] of Object.entries(response)) {
          setValue(
            key as keyof RetroScrubSettings,
            value as RetroScrubSettings[keyof RetroScrubSettings],
          );
        }
      } catch (error) {
        setWarningMessage("Failed to fetch project data.");
        console.error("Failed to fetch project data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchRetroScrubData();
  }, [projectId, campaignId, setValue]);

  // Update maxAgeDays to match frequency if it exceeds frequency
  useEffect(() => {
    if (frequency && maxAgeDays) {
      const maxAgeNum = Number(maxAgeDays);
      const frequencyNum = Number(frequency);

      if (maxAgeNum > frequencyNum) {
        setValue("maxAgeDays", frequency); // Adjust maxAgeDays to match frequency
        alert("Maximum age has been reduced as it cannot exceed frequency.");
      }
    }
  }, [frequency, maxAgeDays, setValue, warningMessage]);

  const onSubmit = async (data: RetroScrubSettings) => {
    setSaveInProgress(true);
    setWarningMessage("");
    try {
      const apiUrl = `Main/Projects/UpdateRetroScrubSettings`;
      const formattedData = {
        ...data,
        maxAgeDays: data.maxAgeDays !== null ? Number(data.maxAgeDays) : null,
        frequency: data.frequency !== null ? Number(data.frequency) : null,
        nextDate: data.nextDate ? new Date(data.nextDate).toISOString() : null,
      };
      await callApi(apiUrl, HttpMethod.POST, formattedData);
      reset(data);
    } catch (error) {
      setWarningMessage("Failed to save settings.");
      console.error("Failed to save settings:", error);
    }
    setSaveInProgress(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <h6 className="mt-3">RetroScrub™</h6>
      <div className="pb-3">
        With many DNC lists to maintain, frequent updates to the various
        databases occur often. After you scrub a list of all Do Not Calls you
        may run a campaign for days or perhaps months. When these DNC database
        updates occur and new records are added you may have an active calling
        campaign that contains new DNC database entries that were not in the
        database at the time of scrubbing. Businesses using RetroScrub™ are
        assured their active call lists are monitored. Any new DNC records that
        are updated, RetroScrub™ alerts the user via an email warning that a
        DNC record may be contained in an active campaign. Each time a DNC
        database update occurs RetroScrub™ works in the background checking for
        new DNC matches.
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Warning message={warningMessage} />
        {canRetro && (
          <div>
            <b>
              This project will be RetroScrubbed on{" "}
              {nextDate
                ? new Date(nextDate).toLocaleDateString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                  })
                : "N/A"}
            </b>
          </div>
        )}
        <label>
          <input
            type="checkbox"
            className="form-check-input"
            {...register("active")}
            disabled={!canRetro}
          />
          <span className="ps-2">Enable RetroScrub™ for this Project</span>
        </label>
        <hr />
        <h6>E-mail RetroScrub Notifications To:</h6>
        <EmailTextarea
          name="toAddress"
          control={control}
          disabled={!canRetro}
        />
        <hr />
        <h6>Miscellaneous Settings</h6>
        <p>
          Maximum Age of records to RetroScrub (in days):
          <input
            type="text"
            className="form-control"
            {...register("maxAgeDays", {
              validate: (value) => {
                const numValue = Number(value);
                if (isNaN(numValue)) return "Must be a number";
                if (frequency && numValue > Number(frequency)) {
                  return "Max age cannot exceed frequency";
                }
                return true;
              },
            })}
            onInput={(e) => {
              // Allow only numeric values
              e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "");
            }}
            disabled={!canRetro}
          />
        </p>

        <p>
          How frequently to RetroScrub and e-mail notifications:
          <select
            {...register("frequency")}
            id="ddlFrequency"
            className="form-control form-select"
            disabled={!canRetro}
          >
            <option value="7">Every {mapDayOfWeek(dayOfWeek ?? 0)}</option>
            <option value="14">
              Every other {mapDayOfWeek(dayOfWeek ?? 0)}
            </option>
            <option value="28">
              Every fourth {mapDayOfWeek(dayOfWeek ?? 0)}
            </option>
          </select>
        </p>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!isDirty || !canRetro || !isValid}
        >
          {!saveInProgress ? (
            "Save"
          ) : (
            <SaveInProgress isVisible={saveInProgress} />
          )}
        </button>
      </form>
    </>
  );
};

export default RetroScrub;
