import { useState } from "react";
import * as React from "react";
import Loading from "src/components/Loading";
import KeyValuePair from "src/interfaces/KeyValuePair";
import { HttpMethod, callApi } from "src/services/apiService";
import { formatNumber } from "src/utils/formatUtils";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { CpaSection } from "src/interfaces/Main/EBRMaster";
import Warning from "src/components/Warning";

interface DownloadEBRDatabaseProps {
  fetchUndoDetails: () => void;
  access: CpaSection | undefined;
}

const DownloadEBRDatabase: React.FC<DownloadEBRDatabaseProps> = ({
  fetchUndoDetails,
  access,
}) => {
  const { projectId, campaignId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(false);
  const [countResults, setCountResults] = useState<KeyValuePair>();
  const [email, setEmail] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");

  const handleGetCountButtonClick = async () => {
    setIsLoading(true);
    setSuccessMessage("");
    setWarningMessage("");
    const response = await fetchCountResults();
    setCountResults(response);
    setIsLoading(false);
  };

  const fetchCountResults = async (): Promise<KeyValuePair | undefined> => {
    try {
      setIsLoading(true);
      const apiUrl = `main/ebrmaster/getcount?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      return response; // Return the response directly
    } catch (error) {
      console.error("Error fetching count results:", error);
      return undefined;
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadClick = async () => {
    setWarningMessage("");
    let currentCountResults = countResults;

    if (!currentCountResults) {
      currentCountResults = await fetchCountResults();
      if (currentCountResults) {
        setCountResults(currentCountResults);
      }
    }

    const count = parseFloat(currentCountResults?.key ?? "0");
    if (count > 1_000_000) {
      setWarningMessage(
        `The number of records (${formatNumber(count)}) exceeds our direct download limit. 
        Please use the Email feature to retrieve the file.`,
      );
      const emailInput = document.getElementById(
        `email-input`,
      ) as HTMLInputElement;
      if (emailInput) {
        emailInput.focus();
      } else {
        console.error(`Email input not found.`);
      }
      return;
    } else {
      handleDownload();
    }
  };

  const handleEmailClick = async () => {
    await handleDownload(email);
  };

  const handleDownload = async (userEmail: string = "") => {
    setWarningMessage("");
    setSuccessMessage("");
    try {
      const apiUrl = `main/ebrmaster/download?projId=${projectId}&campaignId=${campaignId}${
        userEmail ? `&email=${encodeURIComponent(userEmail)}` : ""
      }`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      const responseData = response.message;

      if (userEmail) {
        // If email is provided, assume the response.message contains the status message
        if (response.type.toLowerCase() === "success") {
          setSuccessMessage(
            "The download link will be emailed to you when it is ready.",
          );
        } else {
          console.error("An error occurred while processing the request.");
        }
      } else {
        const blob = new Blob([responseData], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;

        //const date = new Date().toISOString().slice(0, 10);
        const date = new Date().toLocaleDateString("en-CA");
        const fileName = `EBR_${date}_${projectId}.csv`;

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the object URL and removing the link element
        window.URL.revokeObjectURL(downloadUrl);
        link.parentNode?.removeChild(link);
      }
    } catch (error) {
      setWarningMessage("Download failed. Please try again later.");
      console.error("Download failed:", error);
    }
  };

  const handleDeleteAllClick = async () => {
    try {
      setIsLoading(true);
      const apiUrl = `main/ebrmaster/removeall?projId=${projectId}&campaignId=${campaignId}`;
      const response = await callApi(apiUrl, HttpMethod.DELETE);
      if (response.type.toLowerCase() === "success") {
        console.log("Successfully deleted all records");
        await handleGetCountButtonClick();
        await fetchUndoDetails();
      } else {
        console.log("An unknown error occurred");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (access === undefined) {
    return <Loading />;
  }

  return (
    <>
      <div className="card mt-3">
        <div className="card-header">Download EBR Database</div>
        <div className="card-body position-relative">
          {isLoading && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 10,
              }}
            >
              <Loading />
            </div>
          )}

          <table className="table">
            <thead>
              <tr>
                <td>No of Records</td>
                <td>Last Updated</td>
                <td>
                  {successMessage && <div>{successMessage}</div>}
                  <Warning message={warningMessage} />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                {countResults ? (
                  <>
                    <td>
                      {formatNumber(parseFloat(countResults?.key ?? "0"))}
                    </td>
                    <td>{countResults?.value}</td>
                  </>
                ) : (
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleGetCountButtonClick()}
                      >
                        Get Count
                      </button>
                    </div>
                  </td>
                )}
                <td>
                  <div className="d-flex gap-3 align-items-end">
                    {access.Download && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleDownloadClick()}
                      >
                        Download
                      </button>
                    )}
                    {access.Clear && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleDeleteAllClick()}
                      >
                        Delete All
                      </button>
                    )}
                    {access.Download && (
                      <div className="d-flex flex-column align-items-start">
                        <div>
                          <label
                            className="form-label mb-0"
                            htmlFor={`email-input`}
                          >
                            Email link to download:
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            id={`email-input`}
                            type="text"
                            className="form-control me-2"
                            value={email || ""}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                          />
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => handleEmailClick()}
                          >
                            Email
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default DownloadEBRDatabase;
