import React from "react";

interface ShowIconProps {
  type: string;
  isEnabled?: boolean;
}

const ShowIcon: React.FC<ShowIconProps> = ({ type, isEnabled = true }) => {
  const iconMap: { [key: string]: string } = {
    UsesMaster: "bi bi-patch-check",
    DNC: "bi-file-earmark-lock",
    EBR: "bi-bell",
    RetroScrub: "bi-arrow-repeat",
    EBRNotify: "bi-bell",
    Disabled: "bi-x-circle",
    SubAccount: "bi-lightbulb-fill",
    ScrubUpload: "bi-cloud-arrow-up-fill",
    QuickScrub: "bi-telephone",
  };

  const tooltipMap: { [key: string]: string } = {
    UsesMaster: "Uses Master",
    RetroScrub: "RetroScrub",
    EBRNotify: "EBRNotify",
    SubAccount: isEnabled ? "Sub-Account Enabled" : "Sub-Account Disabled",
    ScrubUpload: "Scrub via Upload",
    QuickScrub: "QuickScrub",
  };

  const iconClass = iconMap[type] || iconMap["Disabled"];
  const iconColorClass = isEnabled ? "text-warning" : "text-secondary";
  const tooltipText = tooltipMap[type] || "";

  return (
    <i
      className={`bi ${iconClass} ${iconColorClass} me-1`}
      style={{ cursor: "default" }}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={tooltipText}
    ></i>
  );
};

export default ShowIcon;
