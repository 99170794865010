import { Link } from "react-router-dom";
import styles from "./Login.module.css";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { callApi, HttpMethod } from "src/services/apiService";
import Cookies from "js-cookie";
import SaveInProgress from "src/components/SaveInProgress";

type FormData = {
  acctId: string;
  username: string;
  email: string;
};

const ResetPassword = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [email, setEmail] = useState<string>("");
  const [saveInProgress, setSaveInProgress] = useState(false);

  useEffect(() => {
    setValue("acctId", Cookies.get("acctId") || "");
  }, [setValue]);

  const onSubmit = async (data: FormData) => {
    setSaveInProgress(true);
    try {
      const apiUrl = "PasswordReset/Reset";
      const response = await callApi(apiUrl, HttpMethod.POST, data);
      setEmail(data.email);
      console.log("Reset password successful:", response);
    } catch (error) {
      alert("Failed to reset password. Please try again.");
      console.error("Failed to reset password:", error);
    }
    setSaveInProgress(false);
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  return (
    <form onSubmit={handleFormSubmit}>
      {email ? (
        <div className="p-1 text-black">
          If your account, username, and email are associated with a DNCScrub
          User, you will receive reset instructions emailed to <b>{email}</b>.
          If you did not receive an email, check your spam folder and ensure
          your account, username, and email address were entered correctly.
          Questions? <a href="malito:help@dnc.com">help@dnc.com</a>
          <Link to="/Login" className="d-block mt-3">
            Back to Login
          </Link>
        </div>
      ) : (
        <>
          <h3 className="text-black fw-bold">Reset Your Password</h3>
          <div className="text-black pb-3">
            Enter your account, username, and email address and we will send you
            instructions to reset your password
          </div>

          <input
            {...register("acctId", { required: "Account ID is required" })}
            className={`${styles["form-control"]} ${errors.acctId ? styles["error-input-box"] : ""}`}
            placeholder="Account"
          />
          {errors.acctId?.message && (
            <div className={styles["error-message"]}>
              {errors.acctId.message}
            </div>
          )}

          <input
            {...register("username", { required: "Username is required" })}
            className={`${styles["form-control"]} ${errors.username ? styles["error-input-box"] : ""}`}
            placeholder="Username"
          />
          {errors.username?.message && (
            <div className={styles["error-message"]}>
              {errors.username.message}
            </div>
          )}

          <input
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address",
              },
            })}
            placeholder="Email"
            className={`${styles["form-control"]} ${errors.email ? styles["error-input-box"] : ""}`}
          />
          {errors.email && (
            <div className={styles["error-message"]}>
              {errors.email.message}
            </div>
          )}

          <button className={styles["login-btn"]} type="submit">
            <SaveInProgress isVisible={saveInProgress} loadingText="" />
            {!saveInProgress && <>Reset Password</>}
          </button>
          <Link to="/Login" className="d-block mt-3">
            Back to Login
          </Link>
        </>
      )}
    </form>
  );
};

export default ResetPassword;
