import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { HttpMethod, callApi } from "src/services/apiService";
import SaveInProgress from "src/components/SaveInProgress";
import Loading from "src/components/Loading";
import ILitigatorScrubOptions from "src/interfaces/Admin/ILitigatorScrubOptions";
import LitigatorScrubSettingsDocumentation from "./LitigatorScrubSettingsDocumentation";

interface LitigatorScrubSettingsProps {
  scrubOptionsId?: number;
  campaignId?: number;
  onSaveTrigger?: (saveHandler: () => void) => void;
  showInScrubOptions?: boolean;
  disableForm: boolean;
  pageSource: string;
}

const LitigatorScrubSettings: React.FC<LitigatorScrubSettingsProps> = ({
  scrubOptionsId,
  campaignId, //Abhi: Adding campaignId here as prop instead of getting from context as this component is called from admin setttings
  onSaveTrigger,
  showInScrubOptions = false,
  disableForm = false,
  pageSource = "Account",
}) => {
  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ILitigatorScrubOptions>({
    mode: "onChange",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [showHelp, setShowHelp] = useState(false);

  const [isScrubAddedAfterChecked, setIsScrubAddedAfterChecked] =
    useState(false);

  const [
    isNumOfLawsuitsOrComplaintsChecked,
    setNumOfLawsuitsOrComplaintsChecked,
  ] = useState(false);

  const fetchSettings = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `main/litigatorscruboptions/get${
        scrubOptionsId ? `?scrubOptionId=${scrubOptionsId}` : ""
      }`;

      const response = await callApi(apiUrl, HttpMethod.GET);

      if (response) {
        const transformedResponse = {
          ...response,
          // We use 0 to indicate no value in the db
          scrubOnlyRecordsAddedAfter:
            response.scrubOnlyRecordsAddedAfter === 0
              ? ""
              : response.scrubOnlyRecordsAddedAfter,
          numOfLawsuitsOrComplaints:
            response.numOfLawsuitsOrComplaints === 0
              ? ""
              : response.numOfLawsuitsOrComplaints,
        };

        if (response.scrubOnlyRecordsAddedAfter === 0) {
          setIsScrubAddedAfterChecked(false);
        } else {
          setIsScrubAddedAfterChecked(true);
        }
        if (response.numOfLawsuitsOrComplaints === 0) {
          setNumOfLawsuitsOrComplaintsChecked(false);
        } else {
          setNumOfLawsuitsOrComplaintsChecked(true);
        }

        reset(transformedResponse);
      }
      setWarningMessage("");
    } catch (error) {
      setWarningMessage("Failed to fetch Litigator Scrub settings.");
      console.error("Failed to fetch settings:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [reset, scrubOptionsId]);

  const onSubmit = async (
    data: ILitigatorScrubOptions,
    //triggeredFromScrubOptions = false,
  ) => {
    //Abhi: We cannot do this as the scruboptions id may have changed and we need to save the record with the new id
    // if (triggeredFromScrubOptions && !isDirty) {
    //   console.log("Form is not modified. Skipping API call.");
    //   return;
    // }

    setSaveInProgress(true);
    try {
      const queryParams = [
        campaignId ? `campaignId=${campaignId}` : null,
        scrubOptionsId ? `scrubOptionId=${scrubOptionsId}` : null,
        `source=${pageSource}`,
      ]
        .filter(Boolean)
        .join("&");

      const apiUrl = `main/litigatorscruboptions/save${queryParams ? `?${queryParams}` : ""}`;

      const transformedData = {
        ...data,
        scrubOnlyRecordsAddedAfter:
          data.scrubOnlyRecordsAddedAfter === null
            ? 0
            : Number(data.scrubOnlyRecordsAddedAfter),
        numOfLawsuitsOrComplaints:
          data.numOfLawsuitsOrComplaints === null
            ? 0
            : Number(data.numOfLawsuitsOrComplaints),
      };
      const response = await callApi(apiUrl, HttpMethod.POST, transformedData);
      if (response && response.type === "SUCCESS") {
        scrubOptionsId = response.message;
        setWarningMessage("");
        console.log(
          `Litigator Scrub Settings saved successfully - ${response.message}`,
        );
      } else {
        console.log("Failed to save Litigator Scrub settings.");
      }
    } catch (error) {
      setWarningMessage("Failed to save Litigator Scrub settings.");
      console.error("Failed to save settings:", error);
    } finally {
      setSaveInProgress(false);
    }
  };

  useEffect(() => {
    if (onSaveTrigger) {
      onSaveTrigger(() => handleSubmit((data) => onSubmit(data))());
    }
  }, [onSaveTrigger, handleSubmit, onSubmit]);

  if (isLoading) {
    return <Loading />;
  }

  watch("scrubOnlyRecordsAddedAfter");
  watch("numOfLawsuitsOrComplaints");

  return (
    <div
      className={`container-fluid overflow-y-auto w-100 ${showInScrubOptions ? "m-0 p-0" : ""}`}
    >
      {warningMessage && (
        <div className="mt-3 alert alert-danger">{warningMessage}</div>
      )}

      <div className="text-start p-3">
        {!showInScrubOptions && <h1>Litigator Scrub Settings</h1>}
        <div className="row">
          <div
            className="col-lg p-0 m-0"
            style={{
              ...(showInScrubOptions ? {} : { minWidth: 840 }),
            }}
          >
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <fieldset disabled={disableForm}>
                <div className={`card`}>
                  <div className="card-header d-flex justify-content-between align-items-center">
                    {!showInScrubOptions ? (
                      <>
                        Default Account Configuration
                        <small className="text-muted ps-3">
                          [{pageSource} level]
                        </small>
                      </>
                    ) : (
                      <>
                        Litigator Settings
                        <small className="text-muted ps-3">
                          [{pageSource} level]
                        </small>
                        <button
                          type="button"
                          className="btn btn-link ms-auto"
                          onClick={() => setShowHelp((prev) => !prev)}
                        >
                          <i className="bi bi-book m-2"></i>
                          {showHelp
                            ? "Hide Documentation"
                            : `Show Documentation`}
                        </button>
                      </>
                    )}
                  </div>

                  <div className="card-body">
                    {showHelp ? (
                      <LitigatorScrubSettingsDocumentation
                        showInScrubOptions={true}
                      />
                    ) : (
                      <>
                        <p>
                          Litigator Scrub Settings allow users to customize
                          their filtering criteria to identify potential
                          high-risk contacts associated with litigation. With
                          these settings, you can selectively enable feeds for
                          TCPA Plaintiffs, Known Associates, Known Family
                          Members, and other categories like FDCPA, TIL, and
                          FCRA Plaintiffs.
                        </p>
                        <span className="fw-bold">Select feeds:</span>

                        {/* TCPA Plaintiffs */}
                        <div className="form-check">
                          <input
                            {...register("tcpaPlaintiffs")}
                            className="form-check-input"
                            type="checkbox"
                            id="tcpaPlaintiffs"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tcpaPlaintiffs"
                          >
                            TCPA Plaintiffs
                          </label>
                        </div>

                        {/* TCPA Plaintiffs Family */}
                        <div className="form-check">
                          <input
                            {...register("tcpaPlaintiffsFamily")}
                            className="form-check-input"
                            type="checkbox"
                            id="tcpaPlaintiffsFamily"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tcpaPlaintiffsFamily"
                          >
                            TCPA Plaintiffs Known Family Members
                          </label>
                          <div className="mb-3">
                            Association strength:
                            <Controller
                              name="tcpaFamilyConfidenceLevel"
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="range"
                                  className="form-range"
                                  min="1"
                                  max="10"
                                />
                              )}
                            />
                            <div className="d-flex justify-content-between">
                              <small>1 (Lowest; Most Matches)</small>
                              <small>10 (Highest; Fewest Matches)</small>
                            </div>
                          </div>
                        </div>

                        {/* TCPA Plaintiffs Known Associates */}
                        <div className="form-check">
                          <input
                            {...register("tcpaPlaintiffsAssociates")}
                            className="form-check-input"
                            type="checkbox"
                            id="tcpaPlaintiffsAssociates"
                          />

                          <label
                            className="form-check-label"
                            htmlFor="tcpaPlaintiffsAssociates"
                          >
                            TCPA Plaintiffs Known Associates
                          </label>
                          <div className="mb-3">
                            Association strength:
                            <Controller
                              name="tcpaAssociatesConfidenceLevel"
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="range"
                                  className="form-range"
                                  min="1"
                                  max="10"
                                />
                              )}
                            />
                            <div className="d-flex justify-content-between">
                              <small>1 (Lowest; Most Matches)</small>
                              <small>10 (Highest; Fewest Matches)</small>
                            </div>
                          </div>
                        </div>

                        <div className="form-check">
                          <input
                            {...register("fdcpA_TIL_FCRA_Plaintiffs")}
                            className="form-check-input"
                            type="checkbox"
                            id="fdcpA_TIL_FCRA_Plaintiffs"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="fdcpA_TIL_FCRA_Plaintiffs"
                          >
                            FDCPA, TIL, FCRA Plaintiffs
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            {...register("tcpaDemandLetters")}
                            className="form-check-input"
                            type="checkbox"
                            id="tcpaDemandLetters"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tcpaDemandLetters"
                          >
                            TCPA Demand Letters
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            {...register("tcpaCrowdSourced")}
                            className="form-check-input"
                            type="checkbox"
                            id="tcpaCrowdSourced"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="tcpaCrowdSourced"
                          >
                            TCPA Crowdsourced
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            {...register("attorneys")}
                            className="form-check-input"
                            type="checkbox"
                            id="attorneys"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="attorneys"
                          >
                            Attorneys
                          </label>
                        </div>

                        {/* Phone numbers associated with more than _ lawsuits or compalints */}
                        <div className="form-check d-flex align-items-center">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            id="numOfLawsuitsOrComplaintsCB"
                            checked={isNumOfLawsuitsOrComplaintsChecked}
                            onChange={(e) => {
                              setNumOfLawsuitsOrComplaintsChecked(
                                e.target.checked,
                              );
                              if (!e.target.checked) {
                                setValue("numOfLawsuitsOrComplaints", ""); // Clear numeric input
                              }
                            }}
                          />
                          <label
                            className="form-check-label me-2"
                            htmlFor="numOfLawsuitsOrComplaintsCB"
                          >
                            Phone numbers associated with more than
                          </label>
                          <input
                            {...register("numOfLawsuitsOrComplaints", {
                              validate: (value) => {
                                if (!isNumOfLawsuitsOrComplaintsChecked)
                                  return true; // Skip validation if checkbox is not checked
                                if (value === "") {
                                  return "Please enter number of lawsuits or complaints";
                                }
                                const numericValue = parseFloat(value);
                                return (
                                  (!isNaN(numericValue) && numericValue >= 1) ||
                                  "Please enter a number 1 or greater"
                                );
                              },
                            })}
                            type="number"
                            className="form-control w-auto me-2"
                            id="numOfLawsuitsOrComplaints"
                            min="1"
                            step="1"
                            style={{ maxWidth: "70px" }}
                            disabled={!isNumOfLawsuitsOrComplaintsChecked} // Disable input unless checkbox is checked
                          />
                          <span>lawsuits or compalints</span>
                        </div>
                        {errors.numOfLawsuitsOrComplaints && (
                          <div className="text-danger">
                            {errors.numOfLawsuitsOrComplaints.message}
                          </div>
                        )}

                        {/* Action was taken within the last _ years */}
                        <div className="form-check d-flex align-items-center">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            id="actionTakenCB"
                            checked={isScrubAddedAfterChecked}
                            onChange={(e) => {
                              setIsScrubAddedAfterChecked(e.target.checked);
                              if (!e.target.checked) {
                                setValue("scrubOnlyRecordsAddedAfter", "");
                              }
                            }}
                          />
                          <label
                            className="form-check-label me-2"
                            htmlFor="actionTakenCB"
                          >
                            Action was taken within the last
                          </label>
                          <input
                            {...register("scrubOnlyRecordsAddedAfter", {
                              validate: (value) => {
                                if (!isScrubAddedAfterChecked) return true; // Skip validation if checkbox is not checked
                                if (value === "") {
                                  return "Please enter the years of actions to scrub within";
                                }
                                const numericValue = parseFloat(value);
                                return (
                                  (!isNaN(numericValue) && numericValue >= 1) ||
                                  "Please enter a number 1 or greater"
                                );
                              },
                            })}
                            type="number"
                            className="form-control w-auto me-2"
                            id="scrubOnlyRecordsAddedAfter"
                            min="1"
                            step="1"
                            style={{ maxWidth: "70px" }}
                            disabled={!isScrubAddedAfterChecked} // Disable input unless checkbox is checked
                          />
                          <span>years</span>
                        </div>
                        {errors.scrubOnlyRecordsAddedAfter && (
                          <div className="text-danger">
                            {errors.scrubOnlyRecordsAddedAfter.message}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {!showInScrubOptions && (
                  <div className="mb-5">
                    <button type="submit" className="btn btn-primary mt-2">
                      {!saveInProgress ? (
                        "Save Default Litigator Scrub Settings"
                      ) : (
                        <SaveInProgress isVisible={saveInProgress} />
                      )}
                    </button>

                    <button
                      type="button"
                      className="btn btn-secondary mt-2 ms-2"
                      onClick={fetchSettings}
                    >
                      Refresh
                    </button>
                  </div>
                )}
              </fieldset>
            </form>
          </div>
          {!showInScrubOptions && (
            <div className="col-lg">
              <LitigatorScrubSettingsDocumentation showInScrubOptions={false} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LitigatorScrubSettings;
