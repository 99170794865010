import { createRef, useState, useEffect, useCallback, useMemo } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import { useUploadListContext } from "src/contexts/UploadListContext";
import Loading from "src/components/Loading";
import "@progress/kendo-theme-default/dist/all.css";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { useForm } from "react-hook-form";
import Warning from "src/components/Warning";

const DNCPolicy = () => {
  const editor = createRef<Editor>();
  const { campaignId, projectId } = useUploadListContext();
  const [isLoading, setIsLoading] = useState(true);
  const [policyData, setPolicyData] = useState("Loading...");
  const [originalPolicyData, setOriginalPolicyData] = useState("");
  const [policyTemplateData, setPolicyTemplateData] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm();

  const defaultValues = useMemo(
    () => ({
      policyData: "Loading...",
    }),
    [],
  );

  const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    InsertTable,
    Undo,
    Redo,
  } = EditorTools;

  const fetchDNCPolicy = useCallback(async (projId: string, campId: string) => {
    setIsLoading(true);
    try {
      const apiUrl = `Main/DncPolicy/GetPolicy?projId=${projId}&campaignId=${campId}&policytype=content`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setPolicyData(response.contents);
      setOriginalPolicyData(response.contents);
      setPolicyTemplateData(response.templateContent);
    } catch (error) {
      console.error("Failed to fetch project data:", error);
      setPolicyData("");
      setWarningMessage("Failed to load content.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    register("policyData");
    if (campaignId && projectId) {
      fetchDNCPolicy(projectId, campaignId);
    }
    reset(defaultValues);
  }, [projectId, campaignId, fetchDNCPolicy, register, reset, defaultValues]);

  const handleEditorChange = (content: string) => {
    if (content === originalPolicyData) {
      setValue("policyData", content, { shouldDirty: false });
      reset(defaultValues);
    } else {
      setValue("policyData", content, { shouldDirty: true });
    }

    setPolicyData(content);
  };

  const onSubmit = async () => {
    setWarningMessage("");
    try {
      let contents = policyData;
      if (editor.current) {
        const view = editor.current.view;
        if (view) {
          contents = EditorUtils.getHtml(view.state);
        }
      }

      const apiUrl = `main/DncPolicy/Save`;
      const data = {
        projId: projectId,
        campaignId: campaignId,
        contents: contents,
      };

      await callApi(apiUrl, HttpMethod.POST, data);
      reset(defaultValues);
    } catch (error) {
      setWarningMessage("Failed to save settings.");
      console.error("Failed to save settings:", error);
    }
  };

  return (
    <>
      <h6>This is the DNC Policy for this Project:</h6>
      <small>
        Disclaimer: It is our opinion that our default policy template meets all
        Federal and State requirements; however, your private counsel should
        review and approve your internal DNC policy created here.
      </small>
      {isLoading ? <Loading /> : null}
      <br />
      <button
        className="btn btn-primary btn-xs d-none"
        onClick={() => fetchDNCPolicy(projectId, campaignId)}
      >
        Refresh Policy
      </button>
      <form onSubmit={handleSubmit(onSubmit)} className="pt-3">
        <Warning message={warningMessage} />
        <Editor
          tools={[
            [Bold, Italic, Underline],
            [Undo, Redo],
            [AlignLeft, AlignCenter, AlignRight],
            [InsertTable],
            [OrderedList, UnorderedList, Indent, Outdent],
          ]}
          contentStyle={{ height: 320 }}
          value={policyData}
          onChange={(event: any) => handleEditorChange(event.value)}
          ref={editor}
        />
        <br />
        <button
          type="submit"
          className="btn btn-primary me-2"
          disabled={!isDirty}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-primary me-2"
          onClick={() => {
            setPolicyData(originalPolicyData);
          }}
        >
          Reset
        </button>
        <button
          type="button"
          className="btn btn-primary me-2"
          onClick={() => {
            setPolicyData(policyTemplateData);
            setValue("policyData", policyTemplateData, { shouldDirty: true });
          }}
        >
          Load Default Template
        </button>
      </form>
    </>
  );
};

export default DNCPolicy;
